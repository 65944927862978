<template>
  <div class="abstract pb-1">
    <div class="p-sm-4">
      <h1 class="h3 text-uppercase b-600 text-primary text-center mb-0 headings">
        <i>Abstract Submission</i>
      </h1>
    </div>
    <div class="bg-secondary p-4">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 mx-auto">
            <div class="card card-body rounded-0">
              <div class="row justify-content-between">
                <div class="col-sm-6">
                  <h1 class="h5 fst-italic b-600 text-primary mb-1">
                    ABSTRACT SUBMISSION IS NOW OPEN!
                  </h1>
                  <p class="mb-0">
                    The abstract can be submitted directly via the online form.
                    No specific template is required.
                  </p>
                </div>
                <div class="col-sm-4">
                  <div class="card card-body bg-primary rounded-0 p-3 text-center">
                    <button @click="showMainAbstract = !showMainAbstract"
                            class="text-uppercase btn btn-warning text-primary b-600 rounded-0 w-75 mx-auto mb-2">
                      Submit Abstract
                    </button>
                    <p class="text-white mb-0 fst-italic">
                      Deadline: 21 Apr 2023
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-10 mx-auto">
          <div class="card card-body p-3 pt-4">
            <transition :duration="300" name="fade">
              <div v-if="showMainAbstract">
                <div class="content mb-4">
                  <img src="@/assets/img/abstract/important-dates.png" class="img-fluid" alt="table image"/>
                </div>
                <div class="content">
                  <h2 class="h5 fw-bold text-primary topics">Topics:</h2>
                  <div class="py-1">
                    <div class="d-flex align-items-start">
                      <img src="@/assets/img/abstract/icons/1.png" class="img-fluid me-3" width="70" alt=""/>
                      <div>
                        <p class="topic text-primary fw-bold mb-0">
                          Advanced Techniques for Surface Engineering Towards
                          Enhanced Performance​
                        </p>
                        <ul style="padding-left: 20px">
                          <li>
                            New and advanced surface engineering technologies
                          </li>
                          <li>
                            Surface engineering in industrial practice (Shot
                            Peening, Hammer Peening, Deep Cold Rolling,
                            Ultrasonic Shot Peening)
                          </li>
                          <li>
                            Laser surface treatment (Laser Shock Peening,
                            Finishing and Texturing)
                          </li>

                          <li>
                            Surface engineering / post processing for additively
                            manufactured components
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="py-1">
                    <div class="d-flex align-items-start">
                      <img src="@/assets/img/abstract/icons/2.png" class="img-fluid me-3" width="70" alt=""/>
                      <div>
                        <p class="topic text-primary fw-bold mb-0">
                          Surface and Sub-Surface Characterisation (In-Situ and
                          Ex-Situ)
                        </p>
                        <ul style="padding-left: 20px">
                          <li>
                            Evaluation of surfaces properties: AFM, SEM, TEM
                          </li>
                          <li>
                            Erosion & corrosion assessment (cavitation erosion,
                            wear, erosion corrosion)
                          </li>
                          <li>Quality and consistency of surface treatments</li>
                          <li>
                            Mechanical properties (complex geometry, thin film,
                            small-scale mechanical property, in-situ
                            measurement)
                          </li>
                          <li>Characterisation of coatings and interfaces</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="py-1">
                    <div class="d-flex align-items-start">
                      <img src="@/assets/img/abstract/icons/3.png" class="img-fluid me-3" width="70" alt=""/>
                      <div>
                        <p class="topic text-primary fw-bold mb-0">
                          Simulation and Modelling of Surface Integrity​
                        </p>
                        <ul style="padding-left: 20px">
                          <li>Process simulation</li>
                          <li>Machine learning in surface engineering</li>
                          <li>Automation in surface engineering</li>
                          <li>Damage mechanics at varied length scales</li>
                          <li>Residual stresses and their relaxation</li>
                          <li>Structure-property correlation</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="py-1">
                    <div class="d-flex align-items-start">
                      <img src="@/assets/img/abstract/icons/4.png" class="img-fluid me-3" width="70" alt=""/>
                      <div>
                        <p class="topic text-primary fw-bold mb-0">
                          Non-Destructive Inspection and In-Line Monitoring for
                          Quality Control​
                        </p>
                        <ul style="padding-left: 20px">
                          <li>Manufacturing</li>
                          <li>Production</li>
                          <li>Repair and maintenance</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="py-1">
                    <div class="d-flex align-items-start">
                      <img src="@/assets/img/abstract/icons/5.png" class="img-fluid me-3" width="70" alt=""/>
                      <div>
                        <p class="topic text-primary fw-bold mb-0">
                          Advanced Coating Materials: Design, Synthesis, and
                          Industry Applications
                        </p>
                        <ul style="padding-left: 20px">
                          <li>New coating materials: High entropy alloys</li>
                          <li>
                            Protective and functional coatings: anti-corrosion
                            coatings, etc
                          </li>
                          <li>Polymer and paint coatings</li>
                          <li>
                            Conversion Coating: Carburizing, Nitriding,
                            electro-plating, and Anodizing
                          </li>
                          <li>Chemical and Physical Vapour Deposition</li>
                          <li>
                            Additive manufactured coatings: Thermal spraying,
                            cold spraying
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="py-1">
                    <div class="d-flex align-items-start">
                      <img src="@/assets/img/abstract/icons/6.png" class="img-fluid me-3" width="70" alt=""/>
                      <div>
                        <p class="topic text-primary fw-bold mb-0">
                          Emerging Trends in Surface Engineering: Green
                          Technologies Towards Resilience and Sustainability
                        </p>
                        <ul style="padding-left: 20px">
                          <li>Hydrogen economy</li>
                          <li>
                            Clean energies: Low carbon emission, net zero
                            processes
                          </li>
                          <li>Surface engineering in e-mobility</li>
                          <li>
                            Self-cleaning technology (hydrophobic, oleophobic)
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="content">
                  <h2 class="h5 b-600">
                    Long abstract or full Paper submission:
                  </h2>
                  <p>
                    The notification of abstract acceptance will be issued to
                    the corresponding author. After acceptance,
                    the authors have two options:
                  </p>
                  <ol>
                    <li class="mb-1">
                      Submit long abstract (max. 2 pages), or
                    </li>
                    <li>Full paper (3-8 pages)</li>
                  </ol>
                  <p>
                    Submitted papers will be peer reviewed by 1-2 reviewers. All
                    the papers accepted for INCASE 2023 will be published in
                    LNME, lecture Notes in Mechanical Engineering, Springer
                    (ISSN: 2195-4356), which is indexed by Scopus.
                  </p>
                  <p>
                    Template for full paper submission
                    <a href="/Call for Paper Sample-modified on 8 May 2023.docx" target="_blank">here</a>.
                  </p>
                  <p>
                    Please kindly take note that INCASE 2023 does not encourage
                    multiple papers for one registration. Registration will be
                    pegged to a paper ID: one paper per registration. A poster
                    paper receives the same privilege as an oral paper. A
                    corresponding author can be the same for multiple papers,
                    but each paper should have a different registered author
                    participating in the conference.
                  </p>
                  <p>Abstract submission closes on 21 April 2023</p>
                  <p>
                    For more information, please contact <br/>
                    <a href="mailto:info@incase2023.org" target="_blank">
                      info@incase2023.org
                    </a>
                  </p>
                </div>

                <div class="row mt-5">
                  <div class="col-sm-2 col-6 mx-auto">
                    <div class="d-grid">
                      <button class="btn text-dark btn-warning b-600" @click="showMainAbstract = !showMainAbstract">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <transition :duration="300" name="fade">
              <div v-if="!showMainAbstract">
                <h1 class="h5 text-uppercase b-500 mb-4">Submission form</h1>
                <div class="row">
                  <div class="col-sm-12 mx-auto">
                    <div class="form">
                      <div class="row">
                        <div class="col-sm-6 mb-3">
                          <label class="form-label">Presenting Author's Name:</label>
                          <input type="text" v-model="participant.name" class="form-control"/>
                          <span class="ms-3 text-danger" v-for="message of validation_message.name"
                                :key="`name-${message}`">{{ message }}<br/></span>
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label class="form-label">Affiliation:</label>
                          <input type="text" v-model="participant.affiliation" class="form-control"/>
                          <span class="ms-3 text-danger" v-for="message of validation_message.affiliation"
                                :key="`affiliation-${message}`">{{ message }}<br/></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 mb-3">
                          <label class="form-label">Email:</label>
                          <input type="email" v-model="participant.email" class="form-control"/>
                          <span class="ms-3 text-danger" v-for="message of validation_message.email"
                                :key="`email-${message}`">{{ message }}<br/></span>
                        </div>
                      </div>
                      <div class="row mb-3" v-for="(item, index) in participant.co_auther" :key="index">
                        <div class="col-sm-6 mb-3">
                          <label class="form-label">Co-authors name:
                            <small class="text-muted"><i>(optional)</i></small></label>
                          <input type="text" v-model="item.name" class="form-control"/>
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label class="form-label">Affiliation:
                            <small class="text-muted"><i>(optional)</i></small></label>
                          <input type="text" v-model="item.affiliation" class="form-control"/>
                        </div>
                      </div>
                      <div class="d-flex align-items-center gap-2 mb-3">
                        <button class="btn btn-outline-dark btn-sm" @click="addMembers()">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                          </svg>
                        </button>
                        <span>Add Additional names</span>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Paper Title:</label>
                        <input type="text" v-model="participant.paper_title" class="form-control"/>
                        <span class="ms-3 text-danger" v-for="message of validation_message.paper_title"
                              :key="`paper_title-${message}`">{{ message }}<br/></span>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 mb-3">
                          <label class="form-label">Presentation mode:</label>
                          <select class="form-select" v-model="participant.presentation_mode">
                            <option selected value="Oral">Oral</option>
                            <option value="Poster">Poster</option>
                          </select>
                          <span class="ms-3 text-danger" v-for="message of validation_message.presentation_mode"
                                :key="`presentation_mode-${message}`">{{ message }}<br/></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 mb-3">
                          <label class="form-label">Topics:</label>
                          <select class="form-select" v-model="participant.topic">
                            <option selected
                                    value="Advanced Techniques for Surface Engineering Towards Enhanced Performance">
                              Advanced Techniques for Surface Engineering
                              Towards Enhanced Performance
                            </option>
                            <option value="Surface and Sub-Surface Characterisation (In-Situ and Ex-Situ)">
                              Surface and Sub-Surface Characterisation (In-Situ
                              and Ex-Situ)
                            </option>
                            <option value="Simulation and Modelling of Surface Integrity">
                              Simulation and Modelling of Surface Integrity
                            </option>
                            <option value="Non-Destructive Inspection and In-Line Monitoring for Quality Control">
                              Non-Destructive Inspection and In-Line Monitoring
                              for Quality Control
                            </option>
                            <option value="Advanced Coating Materials: Design, Synthesis, and Industry Applications">
                              Advanced Coating Materials: Design, Synthesis, and
                              Industry Applications
                            </option>
                            <option
                                value="Emerging Trends in Surface Engineering: Green Technologies Towards Resilience and Sustainability">
                              Emerging Trends in Surface Engineering: Green
                              Technologies Towards Resilience and Sustainability
                            </option>
                            <option value="International Symposium on Cold Spray">
                              International Symposium on Cold Spray
                            </option>
                          </select>
                          <span class="ms-3 text-danger" v-for="message of validation_message.topic"
                                :key="`topic-${message}`">{{ message }}<br/></span>
                        </div>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Main Abstract / Description (150-200 words only)
                        </label>
                        <textarea class="form-control" v-model="participant.description" rows="6"></textarea>
                        <span class="ms-3 text-danger" v-for="message of validation_message.description"
                              :key="`description-${message}`">{{ message }}<br/></span>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 mb-3">
                          <label for="formFile" class="form-label">
                            Attach File
                            <i><small class="text-muted">(if any)</small></i>
                          </label>
                          <input class="form-control" type="file" id="formFile" @change="selectfile"/>
                          <small class="mt-2">If you encounter any difficulties uploading your abstract, please send a
                            copy
                            to info@incase2023.org</small>
                          <span class="ms-3 text-danger" v-for="message of validation_message.file"
                                :key="`file-${message}`">{{ message }}<br/></span>
                        </div>
                        <div class="col-sm-6 mb-3">
                          <label class="form-label">
                            Keywords
                            <i><small class="text-muted">(maximum 5, separated by ",")</small></i>
                          </label>
                          <input type="text" class="form-control" v-model="participant.keywords"/>
                          <div class="ms-3 text-danger" v-if="keywordvalidation">
                            Keyword should less than 5
                          </div>
                          <span class="ms-3 text-danger" v-for="message of validation_message.keywords"
                                :key="`keywords-${message}`">{{ message }}<br/></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row my-3" v-if="this.alert">
                  <div class="col-sm-8 mx-auto">
                    <div class="alert alert-success" role="alert">
                      Your form has been submitted successfully! Thank you.
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-sm-2 col-6 mx-auto" v-if="!loading">
                    <div class="d-grid">
                      <button @click="store()" v-if="!loading" class="btn text-dark btn-warning b-600">
                        Submit
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-2 col-6 mx-auto" v-if="loading">
                    <div class="d-grid">
                      <button class="btn text-dark btn-warning b-600" v-if="loading">
                        Please wait...
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <section class="contactus bg-light pt-4 mb-0">
      <div class="container-lg">
        <div class="d-flex justify-content-center text-center mb-4">
          <h2 class="h2 b-600 mx-auto text-primary text-uppercase fst-italic headings">
            Contact Us
          </h2>
        </div>
        <div class="row justify-content-between mb-3">
          <div class="col-sm-5">
            <p class="text-primary h5 b-600 mb-3">Drop us a message</p>
            <div class="row">
              <div class="col-6 col-sm-6">
                <div class="mb-3">
                  <input type="text" name="name" class="form-control" placeholder="Name"/>
                </div>
              </div>
              <div class="col-6 col-sm-6">
                <div class="mb-3">
                  <input type="email" name="email" class="form-control" placeholder="Email"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="mb-3">
                  <input type="text" name="subject" class="form-control" placeholder="Subject"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <textarea name="message" cols="30" rows="5" class="form-control" placeholder="Message"></textarea>
              </div>
            </div>
            <button class="btn btn-primary rounded-0 text-white b-600 text-uppercase mt-3">
              Submit
            </button>
          </div>
          <div class="col-sm-5">
            <div class="mb-3">
              <p class="text-primary h5 b-600 mb-1">Conference Secretariat</p>
              <a href="mailto:info@incase2023.org" target="_blank" class="text-decoration-none text-dark">
                info@incase2023.org
              </a>
            </div>
            <p>
              <span class="text-primary h5 b-600">Venue:</span> Holiday Inn
              Singapore Atrium
            </p>
            <div class="mapouter">
              <div class="gmap_canvas border border-white border-4 p-2">
                <iframe width="100%" height="300" id="gmap_canvas"
                        src="https://maps.google.com/maps?q=Holiday%20Inn%20Singapore%20Atrium,%20an%20IHG%20Hotel&t=&z=15&ie=UTF8&iwloc=&output=embed"
                        frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <img src="@/assets/img/abstract/footer-bg.png" class="img-fluid" alt="bg image"/>
          </div>
        </div>
      </div>
    </section>

    <footerComp/>
  </div>
</template>

<script>
import Api from "@/assets/js/Api";
import Csrf from "@/assets/js/Csrf";
import footerComp from "@/components/layouts/footer.vue";

export default {
  name: "abstractPage",
  components: {
    footerComp,
  },
  data() {
    return {
      showMainAbstract: true,
      keywordvalidation: false,
      aler: false,
      loading: false,
      file: "",
      participant: {
        name: "",
        affiliation: "",
        email: "",
        co_auther: [{name: "", affiliation: ""}],
        paper_title: "",
        presentation_mode: "",
        description: "",
        topic: "",
        keywords: "",
      },
      validation_message: {
        affiliation: [],
        email: [],
        co_auther: [],
        paper_title: [],
        presentation_mode: [],
        description: [],
        topic: [],
        keywords: [],
        file: [],
      },
    };
  },

  methods: {
    addMembers() {
      this.participant.co_auther.push({
        name: "",
        affiliation: "",
      });
    },
    selectfile(e) {
      const file = e.target.files[0];
      this.file = file;
    },
    store() {
      this.keywordvalidation = false;
      var result = this.participant.keywords.split(",");

      if (result.length > 5) {
        console.log("aaa");
        this.keywordvalidation = true;
        return;
      }

      // Validate file size
      if (this.file && this.file.size > 20000000) {
        // alert("File size must be less than 20 MB.");
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File size must be less than 20 MB.",
        });
        return;
      }

      this.loading = true;
      console.log("participant", this.participant);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("participant", JSON.stringify(this.participant));
      console.log(formData);
      Csrf.getCookie().then((res) => {
        Api.post("abstract", formData).then((res) => {
          if (res.data.validation_error) {
            this.validation_message = res.data.validation_message;
            this.loading = false;
            return;
          }
          if (!res.data.error) {
            console.log("set");
            this.resetValidation();
            this.sucess();
          }
        });
      });
    },

    resetValidation() {
      this.validation_message = {
        affiliation: "",
        email: "",
        co_auther: "",
        paper_title: "",
        presentation_mode: "",
        description: "",
        topic: "",
        keywords: "",
        file: "",
      };
    },
    sucess() {
      this.participant = {
        name: "",
        affiliation: "",
        email: "",
        co_auther: [{name: "", affiliation: ""}],
        paper_title: "",
        presentation_mode: "",
        description: "",
        keywords: "",
      };
      this.file = "";
      this.alert = true;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.abstract {
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;

  .headings {
    font-family: "GothamRounded", sans-serif;
  }

  .topics {
    font-family: "Open Sans", sans-serif;
  }

  & .card {
    border: 0;

    & .content {
      & ol {
        & li {
          margin-bottom: 10px;
        }
      }

      & ul {
        li {
          margin-bottom: 0;
        }
      }
    }

    & .form {
      & .form-label {
        font-weight: 400;

        & span {
          color: red;
        }
      }
    }
  }
}
</style>
